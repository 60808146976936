// import {Button, Col, Image, Row, Typography} from 'antd'
import React, {useRef} from 'react';
import {Button, Card, Col, Divider, Image, Input, message, Row, Select, Typography} from "antd";
import {AppleOutlined, FacebookOutlined, GoogleOutlined, TwitterCircleFilled} from "@ant-design/icons";

import {useAuth} from "../context/AuthProvider";


const Title = Typography.Title;
const AuthPage = () => {

    const {loginWithGoogle, loginWithEmail, loginWithFacebook, loginWithX, loginWithApple, loading} = useAuth();

    const formData = useRef({
        app: 'g',
        email: '',
        password: '',
        inGroups: "G1,G2,G3,G4,G5,G6",
    });

    // eslint-disable-next-line
    function handleAuth() {
        loginWithGoogle(formData.current.app, formData.current.inGroups)
    }

    function handleLoginWithEmailAuth() {
        console.log(formData)
        loginWithEmail(formData.current.app, formData.current.email, formData.current.password, formData.current.inGroups)
    }

    function handleLoginWithFacebookAuth() {
        loginWithFacebook(formData.current.app, formData.current.inGroups)
    }

    function handleLoginWithXAuth() {
        loginWithX(formData.current.app, formData.current.inGroups)
    }

    interface AuthContextData {
        loading: boolean
        app: String
        inGroups: String

    }

    const initial = {
        loading: false,
        formData: {},
        app: "g",
        inGroups: "G1,G2,G3,G4,G5,G6",
    };
    const AuthContext = React.createContext<AuthContextData>(initial as AuthContextData);

    return (
        <AuthContext.Provider value={
            {
                app: formData.current.app,
                inGroups: formData.current.inGroups,
                loading: loading,
            }
        }>
            <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
                <Col xxl={12} xl={12} md={12} lg={12} sm={24} xs={24}>
                    <Row justify="center" style={{width: "100%", marginBottom: "10px"}}>
                        <Row style={{width: "50%", marginBottom: "10px"}}>
                            <Image src={"/login.svg"} alt="Login" preview={false}/>
                        </Row>
                    </Row>

                    <Row justify="center" style={{width: "100%", marginBottom: "26px"}}>
                        <Title>Welcome to Bitus Chat System (BCS)!</Title>
                    </Row>
                    <Row justify='center' style={{marginBottom: 5}}>
                        <Col>
                            <Card title={"Choose APP ID"}>
                                <Select
                                    placeholder="Select the current chating in group"
                                    options={[
                                        {
                                            label: "NONE",
                                            value: ""
                                        },
                                        {
                                            label: "Game Server(Dev)",
                                            value: "g"
                                        },
                                        {
                                            label: "Mock Game Server(Testing purpose)",
                                            value: "m"
                                        }
                                    ]}
                                    defaultValue={"g"}
                                    style={{width: "100%"}}
                                    onChange={(e: string) => {
                                        formData.current.app = e
                                    }}
                                />
                            </Card>
                        </Col>
                        <Col style={{marginLeft: 5}}>
                            <Card title={"Provide groups which you want join in."}>
                                <textarea name="inGroups"
                                          placeholder="In Groups"
                                          id="inGroups"
                                          required
                                          maxLength={100}
                                          style={{
                                              width: 220,
                                              color: "black"
                                          }}
                                          defaultValue={"G1,G2,G3,G4,G5,G6"}
                                          onChange={(e: any) => {
                                              formData.current.inGroups = e.target.value
                                          }}
                                />
                                <div>
                                    <label style={{color: "green"}}>Note:
                                        <ul>
                                            <li>Multi groups please separate by ','</li>
                                        </ul>
                                    </label>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row justify="center">
                        <Col>
                            <Card title={"Login with Google"}>
                                <Button onClick={handleAuth} icon={<GoogleOutlined/>} loading={loading} size={"large"}>
                                    Sign in with Google
                                </Button>
                            </Card>
                        </Col>
                        <Col>
                            <Card title={"Login with Apple"} style={{marginLeft: 5, background: "gray"}}>
                                <Button onClick={
                                    () => {
                                        message.warning("Apple login is not supported yet")
                                    }
                                } icon={<AppleOutlined/>} loading={loading} size={"large"}>
                                    Sign in with Apple
                                </Button>
                            </Card>
                        </Col>
                        <Col>
                            <Card title={"Login with Facebook"} style={{marginLeft: 5}}>
                                <Button onClick={
                                    handleLoginWithFacebookAuth
                                } icon={<FacebookOutlined/>} loading={loading} size={"large"}>
                                    Sign in with Facebook
                                </Button>
                            </Card>
                        </Col>
                        <Col>
                            <Card title={"Login with X"} style={{marginLeft: 5}}>
                                <Button onClick={
                                    handleLoginWithXAuth
                                } icon={<TwitterCircleFilled/>} loading={loading} size={"large"}>
                                    Sign in with X
                                </Button>
                            </Card>
                        </Col>

                    </Row>
                    <Row justify="center"><label style={{color: "yellow"}}>OR</label></Row>
                    <Card title={"Sign In with GameServer Email/Username & Password "}>
                        <form id={"usernamePasswordLoginForm"}
                              name={"usernamePasswordLoginForm"}>
                            <Row justify={"center"} style={{marginBottom: 5}}>
                                <Col style={{width: 60}}>
                                    <label htmlFor="email">Email or<br/>
                                        Username:</label>
                                </Col>
                                <Col>
                                    <Input name="email"
                                           size="large"
                                           placeholder="Email"
                                           id="email"
                                           required
                                           maxLength={100}
                                           style={{
                                               width: 220,
                                               color: "white"
                                           }}
                                           allowClear={true}
                                           onChange={(e: any) => {
                                               formData.current.email = e.target.value
                                           }}
                                    />
                                </Col>
                            </Row>
                            <Row justify='center' style={{marginBottom: 5}}>
                                <Col style={{width: 60}}>
                                    <label htmlFor="password">Password:</label>
                                </Col>
                                <Col>
                                    <Input name="password"
                                           type="password"
                                           placeholder="password"
                                           id="password"
                                           required
                                           maxLength={100}
                                           style={{
                                               width: 220,
                                               color: "white"
                                           }}
                                           allowClear={true}
                                           onChange={(e: any) => {
                                               formData.current.password = e.target.value
                                           }}
                                    />
                                </Col>
                            </Row>

                            <Row justify="center">
                                <Button onClick={handleLoginWithEmailAuth} icon={<GoogleOutlined/>} loading={loading}
                                        size={"large"}>
                                    Sign in with Email/Username & Password
                                </Button>
                            </Row>
                        </form>
                    </Card>
                </Col>
            </Row>
        </AuthContext.Provider>
    )
};

export default AuthPage