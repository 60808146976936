import React, {useEffect, useState} from 'react';
import {initializeApp} from 'firebase/app';
import {
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
    linkWithCredential,
    signOut,
    TwitterAuthProvider
} from "firebase/auth";
import {message, Spin} from 'antd';
import Auth from '../containers/Auth'
import {signInWithCustomToken, UserCredential} from "@firebase/auth";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

const firebaseConfig = {
    apiKey: "AIzaSyAQ9pfGKqaXmyCrcuzWMjLg2DW1u5TIK5s",
    authDomain: "omniland-389817.firebaseapp.com",
    projectId: "omniland-389817",
    storageBucket: "omniland-389817.appspot.com",
    messagingSenderId: "534334309707",
    appId: "1:534334309707:web:6ba213bde21e4349d354ce",
    measurementId: "G-ND1CCZT9GF"
};

let firebaseApp = initializeApp(firebaseConfig);

interface AuthContextData {
    loading: boolean;
    inGroups: String[];
    app: String;

    loginWithEmail(app: string, username: string, password: string, inGroups: string): void;

    loginWithGoogle(app: string, inGroups: string): void;

    loginWithApple(app: string, inGroups: string): void;

    loginWithFacebook(app: string, inGroups: string): void;

    loginWithX(app: string, inGroups: string): void;

    logout(): void;

    user: any;
    currentUser: any;
}

const initial = {
    user: null,

};

const AuthContext = React.createContext<AuthContextData>(initial as AuthContextData);
const auth = getAuth()

function AuthProvider({children}: any) {
    const [loading, setLoading] = useState<boolean>(true);
    const [bootstrapping, setBootstrapping] = useState<boolean>(true);
    const [user, setUser] = useState<any>(null);
    const [inGroups, setInGroups] = useState<String[]>([]);
    const [app, setApp] = useState<String>("");

    function login(app: string, inGroups: string) {

        setApp(app);//注意：app 参数要从自己服务器上读取，不是从页面传过来的
        setInGroups(inGroups.split(","))//注意： 这里要从自己服务器上读取，不是从页面传过来的

        console.log("login with google--->>>", app, inGroups)
        setLoading(true);
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(function (result) {
            console.log("result--->>>", result);
            setUser(result.user);
            // 第一步，这里把用户ID以及用户相关信息传给后台服务，如果用户ID不在后台服务中，后台服务应该注册一个新用户
            // 第二步 用户可访问的组信息以及MD5 应该从API加载
            // 注意，为了测试，这里省略了第一和第二步
            setApp(app);
            setInGroups(inGroups.split(","))//注意： 这里要从自己服务器上读取，不是从页面传过来的
            setLoading(false)
        }).catch(function (error: any) {
            console.log(error.message);
            message.error("Unable to sign in");
            setLoading(false)
        });
    }

    function loginWithApple(app: string, inGroups: string) {
        console.log("login with apple--->>>", app, inGroups)
    }

    function loginWithFacebook(app: string, inGroups: string) {
        setApp(app);//注意：app 参数要从自己服务器上读取，不是从页面传过来的
        setInGroups(inGroups.split(","))//注意： 这里要从自己服务器上读取，不是从页面传过来的

        console.log("login with facebook--->>>", app, inGroups)
        signInWithPopup(auth, new FacebookAuthProvider()).then(function (result) {
            console.log("result--->>>", result);
            setUser(result.user);
            // 第一步，这里把用户ID以及用户相关信息传给后台服务，如果用户ID不在后台服务中，后台服务应该注册一个新用户
            // 第二步 用户可访问的组信息以及MD5 应该从API加载
            // 注意，为了测试，这里省略了第一和第二步
            setApp(app);
            setInGroups(inGroups.split(","))//注意： 这里要从自己服务器上读取，不是从页面传过来的
            setLoading(false)
        }).catch(function (err: any) {
            console.log(err);
            message.error("Unable to sign in");
            setLoading(false)
        });
    }

    function loginWithX(app: string, inGroups: string) {
        setApp(app);//注意：app 参数要从自己服务器上读取，不是从页面传过来的
        setInGroups(inGroups.split(","))//注意： 这里要从自己服务器上读取，不是从页面传过来的
        console.log("login with X--->>>", app, inGroups)

        signInWithPopup(auth, new TwitterAuthProvider())
            .then((result) => {
                    console.log("userCredential--->>>", result);
                    const user = result.user;
                    setUser(user);
                    // 第一步，这里把用户ID以及用户相关信息传给后台服务，如果用户ID不在后台服务中，后台服务应该注册一个新用户
                    // 第二步 用户可访问的组信息以及MD5 应该从API加载
                    // 注意，为了测试，这里省略了第一和第二步
                }
            ).catch(function (error) {

            if (error.code === "auth/account-exists-with-different-credential") {
                let pendingCred = error.credential;
                console.log("pendingCred--->>>", pendingCred)
                // Step 3: 保存pendingCred 到local storage
                localStorage.setItem("pendingCred", pendingCred);
                // Step 4: Let the user know that they already have an account
                message.error("U have already signed in with another account, please sign in with that account")
                // but with a different provider, and let them choose another
                // sign-in method.
            }
        }).finally(() => {
            setLoading(false)
        });

    }

    function loginWithEmail(app: string, email: string, password: string, inGroups: string) {
        if (email === null || password === null || email === "" || password === "") {
            message.error("Email or password cannot be empty")
            return
        }

        if (inGroups === null || inGroups === "") {
            inGroups = "G1,G2,G3"
        }

        setLoading(true);
        console.log("input: ", email, password, inGroups)
        // https://game-server.omh-s2-demo.wephonecloud.com/game/user/login
        // let endpoint = "https://game-server.omh-s2-demo.wephonecloud.com/game/user/login"
        let endpoint = "https://development.omnihorse.io:7103/v3/auth/sign-in"
        let payload = {
            "username": email,
            "password": password,
            "inGroups": inGroups
        }
        // inGroups 参数用页面传 是为了测试方便，这个参数应该 登录校验以后由后台服务加载

        fetch(endpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(payload) // body data type must match "Content-Type" header
        }).then((response) => {
            if (response && response.ok) {
                response.json().then((obj) => {
                    if (obj['code'] === 200) {
                        let token_from_game_server = obj['data']['token'];
                        // send token to firebase
                        console.log("token_from_game_server:", token_from_game_server)
                        signInWithCustomToken(auth, token_from_game_server).then((userCredential) => {
                            console.log("userCredential:", userCredential)
                            // Signed in
                            var user = userCredential.user;
                            console.log("user->", user)
                            setUser(user);
                            setApp(app);
                            setInGroups(inGroups.split(","))//注意： 这里要从自己服务器上读取，不是从页面传过来的
                            setLoading(false)
                            // ...
                        }).catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.log(errorCode, errorMessage)
                        });
                    }
                });
            } else {
                throw new Error("Unable to login");
            }
        }).catch((error) => {
            console.log(error)
        });

    }

    function logout() {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error: any) => {
            console.log(error)
        });
    }

    /** ======================
     *  Hooks
     ---------------------- */
    useEffect(() => {
        setBootstrapping(true);
        setLoading(true);
        onAuthStateChanged(auth, (user: any) => {
            setUser(user);
            setBootstrapping(false);
            setLoading(false)
        });
    }, []);

    return (
        <AuthContext.Provider value={
            {
                user: user,
                loading: loading,
                currentUser: user,
                inGroups: inGroups,
                app: app,
                loginWithGoogle: login,
                loginWithEmail: loginWithEmail,
                loginWithApple: loginWithApple,
                loginWithFacebook: loginWithFacebook,
                loginWithX: loginWithX,
                logout: logout
            }
        }>
            {
                user ? children : bootstrapping ?
                    <Spin spinning={loading} size="large" style={{
                        width: "100%",
                        height: "100vh",
                        lineHeight: "100vh"
                    }}/> :
                    <Auth/>
            }
        </AuthContext.Provider>
    )
}

const useAuth = () => React.useContext(AuthContext);
export {AuthProvider, useAuth}