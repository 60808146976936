import React, {useEffect, useState} from 'react';
import {Col, Row, Layout, Button, Card, Radio, Input, Typography, Divider, message, Select} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined, SyncOutlined} from "@ant-design/icons";
import './App.less';

import {useAuth} from "./context/AuthProvider";
import {useSumu} from "./context/SumuProvider";

import Code from "./containers/Code";

const {Content, Header} = Layout;
const {TextArea} = Input;
const {Paragraph, Text} = Typography;

const App = (props: any) => {

    const {logout} = useAuth();
    const {
        connect,
        disconnect,
        publish,
        send,
        ping,
        connected,
        connecting,
        connectedUsers,
        connectedUserIds,
        getCurrentGroup
    } = useSumu();
    const {
        currentUser,
        inGroups,
        app,
    }
        = useAuth();
    const [pushMode, setPushMode] = useState<string>("UNICAST");
    const [msg, setMsg] = useState<string>("");
    const [users, setUsers] = useState<string[] | undefined>(undefined);
    const [currentChattingGroup, setCurrentChattingGroup] = useState<string | undefined>(undefined);

    useEffect(() => {
        connect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function doPublish() {
        if (pushMode === "MULTICAST" && !(users && users.length !== 0)) {
            message.error("Push mode is multicast, please select users!")
            return
        }

        if (pushMode === "GROUPCAST" && (currentChattingGroup === null || currentChattingGroup === undefined) || currentChattingGroup === "") {
            message.error("Push mode is GROUPCAST, please select chatting group!")
            return
        }

        if (msg) {
            if (pushMode === "GROUPCAST") {
                publish("message", {
                    text: msg,
                    push_mode: pushMode,
                    groups: [currentChattingGroup],
                    group: currentChattingGroup,
                    app: app
                })
            } else {
                publish("message", {
                    text: msg,
                    push_mode: pushMode,
                    users: users,
                    group: currentChattingGroup,
                    app: app
                })
            }
            setMsg("")
        } else
            message.error("Please enter the message!")
    }

    function refresh_users() {
        if (app == null || app === "") {
            message.error("Please select the app first!")
            return
        }
        if (currentChattingGroup == null || currentChattingGroup === "") {
            message.error("Please select the current chatting group first!")
            return
        }
        publish("get_connected_users",
            {
                usage: "contact",
                app: app,
                group: currentChattingGroup
            }
        );
    }

    function doSend() {
        if (pushMode === "MULTICAST" && !(users && users.length !== 0)) {
            message.error("Push mode is multicast, please select users!")
            return
        }
        if (pushMode === "GROUPCAST" && (currentChattingGroup === null || currentChattingGroup === undefined) || currentChattingGroup === "") {
            message.error("Push mode is GROUPCAST, please select chatting group!")
            return
        }

        if (msg) {
            let data: {};
            if (pushMode === "GROUPCAST") {
                data = {
                    text: msg,
                    push_mode: pushMode,
                    users: users,
                    groups: [currentChattingGroup],
                    group: currentChattingGroup,
                    app: app
                };
            } else {
                data = {
                    text: msg,
                    push_mode: pushMode,
                    users: users,
                    group: currentChattingGroup,
                    app: app
                };
            }

            console.log("Send->", data)
            send("message", data);
            setMsg("")
        } else
            message.error("Please enter the message!")
    }

    function doPing() {
        ping()
    }

    function doConnect() {
        connect()
    }

    function doDisconnect() {
        disconnect()
    }

    function stateColor() {
        if (connected && !connecting) return "#00BFA6";
        else if (!connected && !connecting) return "#F50057";
        else if (connecting) return "#00B0FF";
    }

    function connectedUsersOptions() {
        const options: any = []

        connectedUsers.forEach((user: any) => {
            console.log("User(sessionId)->", user)
            options.push({
                label: user,
                value: user
            })
        })
        let idx = 0;
        connectedUserIds.forEach((uid: any) => {
            console.log("User(our user ID)->", uid)
            options[idx].label += "[ " + uid + " ]"
            idx++;
        });
        return options;
    }

    function connectedGroupsOptions() {
        const options: any = [{
            label: "NONE",
            value: ""
        }]
        inGroups.forEach((group: any) => {
            options.push({
                label: group,
                value: group
            })
        })
        return options;
    }


    return (<>
        <Layout>
            <Header
                style={{
                    backgroundColor: "#141414",
                    borderBottom: `1px solid ${stateColor()}`
                }}
            >
                <Row align={"middle"} justify={"space-between"} style={{width: "100%", height: "100%"}}>
                    {connected && !connecting && <CheckCircleOutlined style={{fontSize: 25, color: stateColor()}}/>}
                    {!connected && !connecting && <CloseCircleOutlined style={{fontSize: 25, color: stateColor()}}/>}
                    {connecting && <SyncOutlined spin style={{fontSize: 25, color: stateColor()}}/>}

                    {connectedUsers.size > 0 &&
                        <Text style={{color: "#00BFA6"}}>{`${connectedUsers.size}`} Users Connected</Text>}
                    {connectedUsers.size === 0 && <Text style={{color: "#F50057"}}>No User Connected</Text>}

                    <Button
                        onClick={e => logout()}
                        type="primary"
                        ghost
                    >
                        Logout
                    </Button>
                </Row>
            </Header>

            <Content
                style={{
                    padding: '0 50px',
                    paddingTop: 10,
                    backgroundColor: "#282c34"
                }}
            >

                <Row>
                    <Row style={{width: "100%", marginBottom: 16}} align={"middle"} gutter={16}>
                        <Col span={8} style={{display: 'flex'}}>
                            <Code
                                title={"Publish message"} code={"publish"}
                                onClick={doPublish}
                                disabled={!connected}
                            />
                        </Col>

                        <Col span={8} style={{display: 'flex'}}>
                            {/*<Row style={{width: "100%"}}>*/}
                            {/*    <Image src={"/arch.svg"}/>*/}
                            {/*</Row>*/}


                            <Card title={"Chat-> APP[" + app + "]"} style={{width: "100%"}}>
                                <Row>
                                    <Card title={"My information"} style={{width: "100%"}}>
                                        <Row style={{width: "100%"}}>
                                            <Paragraph type={"secondary"}>
                                                My userId: <Text strong>{currentUser.uid}</Text><br/>
                                                DisplayName: <Text strong>{currentUser.displayName}</Text><br/>
                                                Email: <Text strong>{currentUser.email}</Text><br/>
                                                <Divider/>
                                                I'm in(Groups): <Text strong>
                                                {inGroups.join(", ")}
                                            </Text>
                                                <br/>

                                                I'm chatting in(Group):<Text strong>{currentChattingGroup} </Text><br/>

                                                <Divider/>
                                                Please select the Current chatting group:
                                                <Select
                                                    // mode="tags"
                                                    placeholder="Select the current chating in group"
                                                    options={connectedGroupsOptions()}
                                                    value={currentChattingGroup}
                                                    style={{width: "100%"}}
                                                    onChange={(currentChattingGroup: string) => {
                                                        setCurrentChattingGroup(currentChattingGroup)
                                                        getCurrentGroup(currentChattingGroup)
                                                    }}
                                                />
                                            </Paragraph>
                                        </Row>
                                    </Card>
                                </Row>
                                <Divider/>
                                <Row>
                                    <Card title={"Message"}>
                                        <Row style={{width: "100%"}} justify={"center"}>
                                            <Radio.Group
                                                defaultValue="UNICAST"
                                                onChange={e => setPushMode(e.target.value)}
                                                style={{marginTop: 16, marginBottom: 16}}
                                            >
                                                <Radio.Button value="UNICAST">UNICAST</Radio.Button>
                                                <Radio.Button value="GROUPCAST">GROUPCAST</Radio.Button>
                                                <Radio.Button value="MULTICAST">MULTICAST</Radio.Button>
                                                <Radio.Button value="BROADCAST">BROADCAST</Radio.Button>
                                            </Radio.Group>
                                        </Row>
                                        <Row>
                                            {pushMode === "UNICAST" &&
                                                <Paragraph type={"secondary"}>The message will be sent to <Text strong>just
                                                    you!</Text></Paragraph>}

                                            {pushMode === "GROUPCAST" &&
                                                <Paragraph type={"secondary"}>The message will be sent to <Text
                                                    strong> the
                                                    whole group.</Text></Paragraph>}

                                            {pushMode === "MULTICAST" &&
                                                <Paragraph type={"secondary"}>The message will be sent to <Text strong>selected
                                                    users!</Text>
                                                    &nbsp;&nbsp; <Button size={"large"} onClick={refresh_users}>Refresh
                                                        users</Button>
                                                </Paragraph>}
                                            <br/>
                                            {pushMode === "BROADCAST" &&
                                                <Paragraph type={"secondary"}>The message will be sent to <Text strong>all
                                                    users except you!</Text></Paragraph>}
                                            <TextArea rows={2}
                                                      maxLength={5000}
                                                      allowClear
                                                      showCount
                                                      autoSize={{minRows: 5, maxRows: 5000}}
                                                      placeholder={"Your message"}
                                                      onChange={e => setMsg(e.target.value)}
                                                      style={{width: "100%", marginTop: 4}}
                                                      value={msg}
                                            />
                                        </Row>
                                        {pushMode === "MULTICAST" &&
                                            <>
                                                <Divider/>
                                                <Select
                                                    mode="multiple"
                                                    maxTagCount="responsive"
                                                    placeholder="Select users..."
                                                    options={connectedUsersOptions()}
                                                    value={users}
                                                    style={{width: "100%"}}
                                                    onChange={(selectedUsers: string[]) => {
                                                        setUsers(selectedUsers);
                                                    }}
                                                />
                                            </>
                                        }

                                        {/*{pushMode === "GROUPCAST" &&*/}
                                        {/*    <>*/}
                                        {/*        <Divider/>*/}
                                        {/*        <Select*/}
                                        {/*            maxTagCount="responsive"*/}
                                        {/*            placeholder="Select message destination groups..."*/}
                                        {/*            options={connectedGroupsOptions()}*/}
                                        {/*            value={groups}*/}
                                        {/*            style={{width: "100%"}}*/}
                                        {/*            onChange={(selectedGroups: string[]) => {*/}
                                        {/*                setGroups(selectedGroups);*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*    </>*/}
                                        {/*}*/}
                                    </Card>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={8} style={{display: 'flex'}}>
                            <Code
                                title={"Send message"} code={"send"}
                                onClick={doSend}
                                disabled={!connected}
                            />
                        </Col>

                    </Row>

                    <Row style={{width: "100%"}} gutter={16}>
                        <Col span={8} style={{display: 'flex'}}>
                            <Code
                                title={"Connect"} code={"connect"}
                                onClick={doConnect}
                                loading={connecting}
                                disabled={connected}
                            />
                        </Col>

                        <Col span={8} style={{display: 'flex'}}>
                            <Code
                                title={"Disconnect"} code={"disconnect"}
                                onClick={doDisconnect}
                                disabled={!connected}
                            />
                        </Col>

                        <Col span={8} style={{display: 'flex'}}>
                            <Code
                                title={"Ping"} code={"ping"}
                                onClick={doPing}
                                disabled={!connected}
                            />
                        </Col>
                    </Row>
                </Row>
            </Content>
        </Layout>
    </>)

}

export default App;
